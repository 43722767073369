
$dark-brown: #66402d;
$light-beige: #f8e5c9;

$breakpoint-medium-phone: 'only screen and (min-width: 400px)'; // Only used for a few edge cases.
$breakpoint-tablet: 'only screen and (min-width: 800px)';
$breakpoint-desktop: 'only screen and (min-width: 1024px)';

.beige-box{
  background: $light-beige;
  border: 2px solid #e8c9a6;
  border-radius: 15px;
  padding: 10px 20px;
  margin: 10px 20px;
  @media #{$breakpoint-desktop}{
    padding: 60px 50px;
    margin: 0 50px 0 50px;
  }
}

.veil-of-dust{
  background: url('../images/veil-of-dust/texture-bg.png');
  margin: 0;
  padding: 0;
  color: $dark-brown;
  font-size: 20px;
  a, .pseudo-link{
    // color: inherit;
    text-decoration: underline;
    &:hover, &:focus, &:active{
      cursor: pointer;
      color: inherit;
      text-decoration: none;
    }
  }
  a.purchase {
    color: $dark-brown;
  }

  h1, h2, h3, h4, h5, h6{
    font-family: Georgia, serif;
    font-weight: bold;
  }

  h1{
    background: none;
    color: inherit;
    letter-spacing: inherit;
    text-align: center;
  }

  h3{
    font-size: 24px;
  }

  video{
    margin-top: 0; // over-write styles.css
  }
  
  .container{
    background: none;
    border: none;
    max-width: none;
  }

  // Overwrites side padding from main stylesheet.
  .container main > section {
      padding: 0;
  }

  .top-banner{
    // could potentially create a mixin for this.
    .gradient-sky{
      position: relative;
      padding: 50px 5px 0 5px; // 50px bottom padding is to leave room for the absolutely-positioned mountain range.
      background: rgb(250,251,252);
      background: linear-gradient(180deg, rgba(250,251,252,1) 0%, rgba(193,219,234,1) 100%);
      padding-bottom: 120px;
      // min-height: 450px;
      min-width: 335px; // test
      @media #{$breakpoint-medium-phone}{
        padding-left: 20px;
        padding-right: 20px;
      }
      @media #{$breakpoint-tablet}{
        padding-left: 15%;
        padding-right: 15%;
      }
      .header-flourish{
        display: block;
        margin: 0 auto 0 auto;
        width: 235px;
        &.bottom{
          transform: scaleY(-1);
        }
      }
      .wordmark{
        width: 45%;
        min-width: 350px;
        display: block;
        margin: 10px auto 0 auto;
      }
      p{
        font-size: 22px;
        text-align: center;
        display: block;
        margin: 20px auto 30px auto;
        line-height: 24px;
        @media #{$breakpoint-tablet}{
          max-width: 500px;
        }
      }
      // This is the bottom mountain range
      .desert-horizon{
        position: absolute;
        left: 0;
        bottom: 0;
        height: 145px;
        background: url('../images/veil-of-dust/desert-bg.png') repeat-x;
        background-size: auto 100%;
        width: 100%;
      }
    }

    .gradient-ground{
      background: rgb(202,167,127);
      background: linear-gradient(180deg, rgba(202,167,127,1) 0%, rgba(247,223,186,1) 100%);
      position: relative;
      padding-bottom: 70px;
    }
    video{
      width: 90%;
      max-width: 900px;
      margin: -10px auto 0 auto;
    }
  }

  .subnav{
    padding: 40px 100px 40px 100px;
    font-size: 18px;
    text-align: center;
    background: $light-beige;
    div{
      display: inline-block;
      white-space: nowrap;
    }
    span.divider{
      display: inline-block;
      padding: 0 10px 0 10px;
      visibility: hidden;
      @media #{$breakpoint-tablet}{
        visibility: visible;
      }
    }
  }
  
  .repeating-flourish-divider{
    width: 100%;
    background-repeat: repeat-x;
  }
  
  .dotted-divider{
    background: url('../images/veil-of-dust/dotted-divider.png') no-repeat;
    height: 7px;
    margin: 40px auto;
    background-size: 100% auto;
    width: 80%;
  }

  .section-wrapper{
    margin: 20px 10px 20px 10px;
    padding: 0;
    position: relative;
    @media #{$breakpoint-tablet}{
      margin: 70px 50px 70px 50px;
    }
    @media #{$breakpoint-desktop}{
      margin: 70px auto 70px auto;
      width: 80%;
      max-width: 1200px;
    }

    // This section needs a BIT of finesse, but it's getting pretty close now.
    // It uses `$flourish-size` and `$flourish-offset` to calculate the placement of the flourishes, as well as the width and placement of the divs which hold the dotted borders.
    $flourish-size: 70px;
    $flourish-offset: -8px;
    $proportional-flourish-length: calc(100% - (#{$flourish-size} + #{$flourish-offset}) * 2);
    $proportional-flourish-offset: $flourish-size + $flourish-offset;
    .dotted-border{
      display: none;
      @media #{$breakpoint-tablet}{
        display: block;
      }
      position: absolute;
      &.top, &.bottom{
        left: $proportional-flourish-offset;
        height: 2px;
        width: $proportional-flourish-length;
      }
      &.left, &.right{
        top: $proportional-flourish-offset;
        height: $proportional-flourish-length;
        width: 2px;
      }
      &.top{
        border-top: 2px dotted #996b4d;
      }
      &.left{
        border-left: 2px dotted #996b4d;
      }
      &.right{
        right: 0;
        border-right: 2px dotted #996b4d;
      }
      &.bottom{
        bottom: 0;
        border-bottom: 2px dotted #996b4d;
      }
    }

    // handle four corner flourishes:
    img.corner-flourish{
      width: $flourish-size;
      position: absolute;
      display: none;
      @media #{$breakpoint-tablet}{
        display: block;
      }
      &.left{
        left: $flourish-offset;
      }
      &.top{
        top: $flourish-offset;
      }
      &.right{
        right: $flourish-offset;
        transform: scaleX(-1);
      }
      // Extra nested needed for specific rotations.
      &.bottom{
        bottom: $flourish-offset;
        &.left{
          transform: scaleY(-1);
        }
        &.right{
          transform: scaleX(-1) scaleY(-1);
        }
      }
    }

    .section-header{
      text-align: center;
      margin: 0 auto;
      padding: 30px 0;
      display: flex;
      flex-wrap: nowrap;
      width: 85%;
      @media #{$breakpoint-tablet}{
        width: 65%;
        padding: 60px 0;
      }
      .text{
        display: inline-block;
        flex: 10 1;
        h3{
          font-size: 24px;
        }
        p{
          font-size: 20px;
        }
      }
      img.subheader-flourish{
        flex: 1 1;
        display: none;
        width: auto;
        height: 100%;
        max-height: 100px;
        margin-top: 30px; // This lines it up with the header text.
        &.left{
          margin-right: 40px;
        }
        &.right{
          transform: scaleX(-1);
          margin-left: 40px;
        }
        @media #{$breakpoint-desktop}{
          display: inline-block;
        }
      }
    }

    .section-body{
      @extend .beige-box;
    }
  }

  .subheader-with-side-flourishes{
    text-align: center;
    padding-bottom: 20px;
    // Currently using h1 for the Wishlist component. Can further break them apart or merge them together if needed in the future.
    h1, h3{
      text-align: center;
      display: inline-block;
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 0;
    }
    img{
      display: none;
      width: 20px;
      padding: 0 10px 0 10px;
      &.right{
        transform: scaleX(-1);
      }
      @media #{$breakpoint-medium-phone}{
        display: inline-block;
      }
    }
    &.wishlist{
      // Wishlist has a slightly different treatment
      padding-bottom: 40px;
      padding-top: 40px;
      img{
        width: 30px;
        padding: 0 20px;
      }
    }
  }

  .flexBox{
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    @media #{$breakpoint-tablet}{
      flex-wrap: nowrap;
    }
    > div{
      display: flex;
      flex-direction: column;
      align-items: center;
      &.text-first{
        flex-direction: column-reverse;
      }
    }
    p{
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .video-text-lockup{
    display: flex;
    flex-wrap: wrap;
    @media #{$breakpoint-desktop}{
      flex-wrap: nowrap;
    }
    .copy, video{
      align-self: flex-start;
      h3{
        margin-top: 0;
        margin-bottom: 20px;
      }
      ul{
        padding-left: 20px;
        li{
          margin: 7px 0 7px 0;
          line-height: inherit;
        }
      }
      @media #{$breakpoint-desktop}{
        width: 31%;
        margin: 0 55px 0 15px;
      }
    }
    video{
      margin: 10px 0 10px 0;
      @media #{$breakpoint-desktop}{
        margin: inherit;
        width: 66%;
      }
    }
  }

  .text-width-header{
    p{
      width: 80%;
      margin: 0 auto;
    }
  }
  
}
  

// This component can exist outside of a `section-wrapepr`.
.art-text-lockup{
  @extend .beige-box;
  width: 55%;
  margin: 0 auto 0 auto;
  p:first-of-type{
    margin-top: 0;
  }
  @media #{$breakpoint-tablet}{
    flex-wrap: nowrap;
    align-items: flex-start;
    display: flex;
    img, div{
      width: 50%;
    }
    img{
      margin-right: 45px;
    }
  }
  h4{
    font-size: 30px;
  }
}
