@import url("https://fonts.googleapis.com/css?family=Holtwood+One+SC:400,700");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");

// $breakpoint-mobile
$breakpoint-tablet: "only screen and (min-width: 800px)";
$breakpoint-desktop: "only screen and (min-width: 930px)";
// $breakpoint-desktop-large

$beige-gray: #e1dddc;
$text-color: #727268;
$light-blue: #7895a4;
$green-blue: #4d8c91;
$wood-brown: #cda882;
// $dark-brown: #b29072; // not used.
$cream: #eadbcb;
$gray: #bdb3b2;
$error-red: #cc0000;

@keyframes anim-rotate {
	0% { transform: rotate(0); }
	100% { transform: rotate(360deg); }
}
.spinner {
	animation: anim-rotate 2s infinite linear;
}

body{
  background: $beige-gray;
  margin: 0;
  padding: 0;
  font-family: Lato, helvetica;
  color: $text-color;
  font-size: 18px;
  @media #{$breakpoint-desktop}{
    font-size: 16px;
  }
}

h1, h2{
  color: $text-color;
  letter-spacing: 5px;
  font-family: 'Holtwood One SC', Lato, helvetica;
  sup{
    font-weight: normal;
    font-size: 12px;
  }
  span.subtitle{
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    display: block;
    font-size: 18px;
    letter-spacing: 0;
    font-weight: normal;
    margin-bottom: 30px;
  }
}

h1{
  font-weight: normal;
  background: $light-blue;
  color: #ffffff;
  font-size: 30px;
  padding: 10px;
}

h3{
  font-family: 'Holtwood One SC', Lato, helvetica;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 1px;
  margin: 20px 0 0 0;
}

a{
  color: $green-blue;
  text-decoration: underline;
  [class^="icon-"], [class*=" icon-"] {
    color: $green-blue;
  }
  &:hover, &:active, &:focus{
    text-decoration: underline;
    color: $wood-brown;
    [class^="icon-"], [class*=" icon-"] {
      color: $wood-brown;
    }
  }
}

input, textarea{
  display: block;
}

textarea{
  resize: vertical;
  min-width: 300px;
}

blockquote{
  line-height: 24px;
}

button{
  border-radius: 10px;
  border: 1px solid #000000;
  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 10px 15px 10px 15px;
  margin: 10px 0 10px 0;
  display: inline-block;
  background: $light-blue;
  color: #ffffff;
  &:hover, &:active, &:focus{
    background: $wood-brown;
    color: $text-color;
  }
  &:disabled{
    cursor: progress; // Useful for the current contact form. If we have other buttons in the future, this may not always be appropriate.
  }
  [class^="icon-"], [class*=" icon-"] {
    color: #ffffff;
  }
}

label{
  display: inline-block;
  margin-top: 15px;
}

ul, ol{
  li{
    line-height: 14px;
    margin: 15px 0 15px 0;
  }
}

img{
  max-width: 100%;
  &.inline{
   padding: 10px;
   width: 300px;
   &.left{ float: left; }
   &.right{ float: right; }
  }
}

header{
  text-align: center;
  margin: 0 auto 0 auto;
  display: block;
  max-width: 800px;
  padding: 10px;
  line-height: 20px;
  > a{
    display: block;
  }
}

.container{
  background: #ffffff;
  width: auto;
  margin: 0 auto 0 auto;
  padding-bottom: 20px;
  max-width: 1000px;
  border-left: 2px solid $gray;
  border-right: 2px solid $gray;
  main{
    .our-games{
      .game-homepage-link{
        font-size: 20px;
        text-decoration: none;
        display: block;
        text-align: center;
        margin: 25px 0;
      }
      .game-image{
        display: block;
        margin: 20px auto 20px auto;
        max-height: 250px;
      }
      
    }
    & > section{
      padding: 0 15px 15px 15px;
      @media #{$breakpoint-tablet}{
        padding: 0 40px 40px 40px;
      }
      @media #{$breakpoint-desktop}{
        padding: 0 100px 40px 100px;
      }
    }
    h2{
      padding-top: 10px;
      font-size: 24px;
      line-height: 28px;
      margin: 20px 0 10px 0;
    }
    p{
      line-height: 22px;
      margin: 5px 0 10px 0;
    }
    .form-error{
      margin: 0;
      font-weight: bold;
      font-size: 14px;
      font-style: italic;
      color: $error-red;
    }
  }
}

.main-logo{
  display: block;
  margin: 0 auto 0 auto;
  width: 150px;
}

nav{
  // display: table;
  // width: calc(100% - 8px);
  position: relative;
  border-top: 4px solid $gray;
  border-bottom: 4px solid $gray;
  background: $beige-gray;
  margin-bottom: 20px;
  .links-wrapper{
    text-align: left;
    display: block;
    padding: 3px 10px 3px 10px;
    @media #{$breakpoint-tablet}{
      text-align: center;
    }
    .menu-button{
      cursor: pointer;
      display: block;
      @media #{$breakpoint-tablet}{
        display: none;
      }
    }
    a{
      color: $text-color;
      display: none;
      padding: 5px 30px 5px 5px;
      text-decoration: none;
      font-family: 'Holtwood One SC', Lato, helvetica;
      @media #{$breakpoint-tablet}{
        display: inline-block;
        padding: 5px 30px 5px 30px;
      }
      &.active, &:hover, &:active, &:focus{
        color: $wood-brown;
      }
    }
    &.menu-open{
      a{
        display: block;
      } 
    }
  }
}

// Currently used for promo GIFs of Veil of Dust. Could be changed into a component in the future.
// .multi-box-display{
//   img{
//     width: 95%;
//     height: auto;
//     display: inline-block;
//     margin: 0;
//     vertical-align: top;

//     // class `small` will set them at a smaller size and they will tile across.
//     &.small{
//       margin: 25px auto 0 auto;
//       display: block;
//       max-width: 300px;
//       @media #{$breakpoint-tablet}{
//         margin: 25px 5% 0 0;
//         display: inline-block;
//         width: 40%;
//         height: auto;
//       }
//       @media #{$breakpoint-desktop}{
//         margin: 25px 5% 0 0;
//         display: inline-block;
//         width: 28%;
//         height: auto;
//       }
//     }
//     & + p{
//       margin-bottom: 25px;;
//     }
//   }
// }

.two-col{
  vertical-align: top;
  display: block;
  @media #{$breakpoint-desktop}{
    width: 45%;
    display: inline-block;
    margin: 0 2.5% 0 2.5%;
  }
}

video{
  display: block;
  margin: 20px auto 10px auto;
  max-width: 100%;
}

.video-subtitle{
  text-align: center;
  font-style: italic;
  padding-bottom: 30px;
}

hr{
  margin: 30px 0 30px 0;
}

footer{
  text-align: center;
  padding: 0;
  position: relative;
  hr{
    width: 100%;
    height: 1px;
    border: none;
    margin: 15px 0 15px 0;
    background: $gray;
  }
  p{
    margin: 0 0 4px 0;
    line-height: 18px;
  }
  .social-links{
    margin: 15px 0 15px 0;
    a{
      height: 25px;
      min-width: 25px;
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      [class^="icon-"], [class*=" icon-"] {
        font-size: 22px;
        line-height: 24px;
      }
    }
  }
}

section.joinDiscordCta{
  svg{
    font-size: 40px;
    display: block;
    margin: 0 auto 0 auto;
  }
  a{
    display: block;
    padding: 25px 0 25px 0;
    background: $light-blue;
    color: $cream;
    text-align: center;
    text-decoration: none;
    span{
      display: block;
      margin: 0 auto 0 auto;
      max-width: 375px;
    }
    &:hover, &:active, &:focus{
      text-decoration: none;
      background: $wood-brown;
    }
  }
}

.joinMailingListCta{
  padding: 15px 0 15px 0;
  font-size: 12px;
  background: $cream;
  & > section{
    margin: 0 auto 0 auto;
    display: block;
    width: 320px;
  }
  h2{
    font-size: 14px;
  }
  h2, p, label{
    margin: 0 0 5px 0;
    padding: 0;
  }
}

.clear{
  clear: both;
}

.none{
  display: none;
}

.block{
  display: block;
}
