$breakpoint-tablet: 'only screen and (min-width: 800px)';
$breakpoint-desktop: 'only screen and (min-width: 1024px)';

body.just-my-opinion{
  margin: 0;
  padding: 0;

  .game-logo{
    display: block;
    margin: 0 auto;
  }

  .carousel{
    height: 510px;
    overflow: hidden;
    img{
      display: block;
      position: relative;
      margin: 0 auto;
    }
  }

  .cta{
    text-align: center;
  }
}
